import React from 'react';
import { Input, InputGroup } from 'reactstrap';
import { insensitiveEquals } from '../../../../util/stringUtil';
import { ClearButton } from '../..';

export const DEFAULT_COLOR = '#ffffff';

export function ColorInput({
  defaultColor = DEFAULT_COLOR, id, name, value, onChange,
}: {
  defaultColor?: string;
  id?: string;
  name?: string;
  value: string;
  onChange: (newColor: string) => void;
}) {
  return (
    <InputGroup>
      <Input
        id={id}
        className="form-control-color"
        name={name}
        type="color"
        value={value}
        onChange={(e) => onChange(e.target.value)} />
      {!insensitiveEquals(defaultColor, value) && (
        <ClearButton onClick={() => onChange(defaultColor)} />
      )}
    </InputGroup>
  );
}
