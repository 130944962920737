import React from 'react';

/**
 * Taken from https://www.totaltypescript.com/forwardref-with-generic-components
 * Fixes issue where forward ref doesn't work with generic parameters.
 * Only use if you encounter type errors with forwardRef.
  */
export function genericForwardRef<T, P = object>(
  render: (props: P, ref: React.Ref<T>) => React.ReactElement | null,
): (props: P & React.RefAttributes<T>) => React.ReactElement | null {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return React.forwardRef(render as any) as any;
}
