import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import {
  DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown,
} from 'reactstrap';
import { OrganizationDto } from '../../../../app/api';
import { insensitiveCompare } from '../../../../util';
import { Icon } from '../../../../components/elements';
import { useSortedOrganizations } from '../../hooks';
import { OrganizationListItem } from '../OrganizationsView/OrganizationListItem';

const MAX_RECENT = 5;

export function OrganizationSwitcher({ organizations, selectedOrganization }: {
  organizations: OrganizationListItem[];
  selectedOrganization: OrganizationDto;
}) {
  const sortedOrganizations = useSortedOrganizations(organizations);
  const otherOrganizations = useMemo(() => {
    return sortedOrganizations?.filter((x) => x.id !== selectedOrganization.id)
      .sort((a, b) => insensitiveCompare(a.name, b.name))
      ?? [];
  }, [sortedOrganizations, selectedOrganization]);

  if (otherOrganizations.length <= 0) {
    return null;
  }
  return (
    <UncontrolledDropdown nav inNavbar>
      <DropdownToggle nav caret>
        <Icon icon="organization" />  {selectedOrganization.name}
      </DropdownToggle>
      <DropdownMenu>
        <Link to="..">
          <DropdownItem>
            <Icon icon="organizations" /> Organization List
          </DropdownItem>
        </Link>
        <OrganizationSwitcherSection
          title="Recent"
          organizations={otherOrganizations
            .filter((o) => o.recentIndex >= 0 && o.recentIndex < MAX_RECENT)
            .sort((a, b) => a.recentIndex - b.recentIndex)} />
        <OrganizationSwitcherSection
          title="Favorites"
          organizations={otherOrganizations
            .filter((o) => o.isFavorite)} />
        <OrganizationSwitcherSection
          organizations={otherOrganizations
            .filter((o) => !o.isFavorite && (o.recentIndex < 0 || o.recentIndex >= MAX_RECENT))
            .slice(0, 5)} />
      </DropdownMenu>
    </UncontrolledDropdown>
  );
}

function OrganizationSwitcherSection({ title, organizations }: {
  title?: string;
  organizations: OrganizationListItem[];
}) {
  return organizations.length <= 0
    ? null
    : (
      <>
        <DropdownItem divider />
        {!!title && <li><h6 className="dropdown-header">{title}</h6></li>}
        {
          organizations?.map((organization) => (
            <OrgLink
              organization={organization}
              key={organization.id} />
          ))
        }
      </>
    );
}

function OrgLink({ organization }: { organization: OrganizationDto }) {
  return (
    <Link to={`../${organization.id}`}>
      <DropdownItem>
        {organization.name}
      </DropdownItem>
    </Link>
  );
}
