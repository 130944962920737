import { concat, uniqBy } from 'lodash';
import React, { ReactNode, useMemo } from 'react';
import {
  Button, Card, CardHeader, UncontrolledTooltip,
} from 'reactstrap';
import { FixedSizeList } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import { Resizable } from 'react-resizable';
import {
  // eslint-disable-next-line max-len
  ConstraintDto,
  getRootEntity,
  hasMmTableExtensions,
  MmExtendedTable,
  ProjectCategoryDto,
  ReferentialConstraintDto,
  TableDto,
  TableSummaryDto,
  UniqueConstraintDto,
  useEncodedTableName,
  useTableInfo,
  useUserApiListHarvestTablesQuery,
  useUserApiListTableChildConstraintsByTableNameQuery,
} from '../../../app/api';
import { Icon, LoadingSpinner } from '../../../components/elements';
import { insensitiveCompare } from '../../../util';
import { useCurrentDatabase } from '../DatabaseContext';
import { CategoryPreferenceMap, useLoadedCategoryColors } from '../settings';
import { TableItem, useTableDetails } from '../tables';
import { OnTablePrefetch, useTablePrefetch } from '../tables/useTablePrefetch';
import { useUserPreferenceNumber } from '../../userPreferences';

export function RelationshipButton({ isActive, onClick }: {
  isActive: boolean;
  onClick: () => void,
}) {
  const action = isActive
    ? 'Hide'
    : 'Show';

  return (
    <>
      <Button
        className="btn-sm"
        active={isActive}
        onClick={() => onClick()}
        id="toggle-relationships">
        <Icon icon="relationships" fixedWidth />
      </Button>
      <UncontrolledTooltip placement="top" target="toggle-relationships">
        {action} <br /> parent/child list
      </UncontrolledTooltip>
    </>
  );
}

function ConstraintToRelationshipListTable(constraint: ConstraintDto): RelationshipListTable {
  return {
    name: constraint.tableName,
    id: constraint.tableId,
    schemaName: constraint.schemaName,
    rowCount: constraint.tableRowCount,
    projectCategory: constraint.tableProjectCategory,
  };
}

function TableSummaryToRelationshipListTable(table: TableSummaryDto): RelationshipListTable {
  return {
    ...table,
    rowCount: table.rowCount ?? 0,
  };
}

export type TableSummaryMap = {
  [index: string]: TableSummaryDto;
};

type RelationshipsRenderer = (
  parentTables: RelationshipListTable[],
  childTables: RelationshipListTable[],
) => JSX.Element;

export function Relationships({
  table, tables, childConstraints,
  showDisabledReferences, showInferredReferences, showOverriddenReferences,
  children,
}: {
  table: TableDto | MmExtendedTable | undefined;
  tables: TableSummaryDto[] | undefined,
  childConstraints: ReferentialConstraintDto[] | undefined,
  showDisabledReferences: boolean;
  showInferredReferences: boolean;
  showOverriddenReferences: boolean;
  children: RelationshipsRenderer;
}) {
  const lookupTable = useMemo(() => {
    const tableMap = tables?.filter((t) => t.schemaName === 'V500')
      .reduce((acc: TableSummaryMap, t) => {
        acc[t.name] = t;
        return acc;
      }, {});

    return (tableName: string | undefined | null) => {
      return tableMap && tableName
        ? tableMap[tableName]
        : undefined;
    };
  }, [tables]);

  const parentTables = useMemo(() => {
    return uniqBy(
      concat(
        table?.referentialConstraints
          .filter((c) => c?.isEnabled || showDisabledReferences)
          .map((c) => c.parentConstraint)
          .filter((c): c is UniqueConstraintDto => !!c)
          .map(ConstraintToRelationshipListTable) ?? [],
        hasMmTableExtensions(table)
          ? table?.tableColumns
            .map((c) => getRootEntity(c, showOverriddenReferences))
            .filter((c) => c.rootEntityName)
            .map((c) => lookupTable(c.rootEntityName))
            .filter((t): t is TableSummaryDto => !!t && showInferredReferences)
            .map(TableSummaryToRelationshipListTable)
          : [],
      ),
      'id',
    ).sort((a, b) => insensitiveCompare(a?.name, b?.name));
  }, [
    table, showDisabledReferences, showInferredReferences, showOverriddenReferences, lookupTable,
  ]);

  const childTables = useMemo(() => {
    return uniqBy(
      concat(
        (childConstraints ?? [])
          .filter((c) => c.isEnabled || showDisabledReferences)
          .map(ConstraintToRelationshipListTable) ?? [],
        hasMmTableExtensions(table)
          ? uniqBy(
            table.childReferences.filter((c) => showOverriddenReferences || !c.overrideId),
            (c) => `${c.tableName}.${c.columnName}`.toLocaleUpperCase(),
          )
            .map((c) => lookupTable(c.tableName))
            .filter((t): t is TableSummaryDto => !!t && showInferredReferences)
            .map(TableSummaryToRelationshipListTable)
          : [],
      ),
      'id',
    ).sort((a, b) => insensitiveCompare(a?.name, b?.name));
  }, [
    table,
    childConstraints,
    showDisabledReferences,
    showInferredReferences,
    showOverriddenReferences,
    lookupTable,
  ]);

  return children(parentTables, childTables);
}

export function RelationshipsSidebar({
  table, tables, childConstraints, isLoading, isLoadingChildConstraints, categories, splitHeight,
  showDisabledReferences, showInferredReferences, showOverriddenReferences,
  onTableDetails, onTablePrefetch, onChangeSplit,
}: {
  table: TableDto | MmExtendedTable | undefined;
  tables: TableSummaryDto[] | undefined,
  childConstraints: ReferentialConstraintDto[] | undefined,
  isLoadingChildConstraints: boolean;
  isLoading: boolean;
  showDisabledReferences: boolean;
  showInferredReferences: boolean;
  showOverriddenReferences: boolean;
  categories: CategoryPreferenceMap,
  splitHeight: number,
  onTableDetails: (tableName: string) => void;
  onTablePrefetch: OnTablePrefetch;
  onChangeSplit: (height: number) => void;
}) {
  const placeholder = table
    ? <h5>None</h5>
    : (
      <span className="d-flex align-items-center mx-auto text-center text-muted">
        Please select a single table to see related tables.
      </span>
    );

  return (
    <Relationships
      table={table}
      tables={tables}
      childConstraints={childConstraints}
      showDisabledReferences={showDisabledReferences}
      showInferredReferences={showInferredReferences}
      showOverriddenReferences={showOverriddenReferences}>
      {(parentTables, childTables) => (
        <div className="d-flex flex-column flex-grow-1">
          <h5 className="text-break">Table: {table?.name}</h5>
          <Resizable
            width={0}
            height={splitHeight}
            minConstraints={[0, 250]}
            maxConstraints={[0, 800]}
            axis="y"
            resizeHandles={['s']}
            handle={<span className="p-1" style={{ cursor: 'row-resize' }} />}
            onResize={(_, data) => onChangeSplit(data.size.height)}>
            <div className="d-flex flex-column" style={{ height: `${splitHeight}px` }}>
              <RelationshipList
                items={parentTables}
                categories={categories}
                title="Parents"
                isLoading={isLoading}
                placeholder={placeholder}
                onTableDetails={onTableDetails}
                onTablePrefetch={onTablePrefetch} />
            </div>
          </Resizable>
          <RelationshipList
            items={childTables}
            categories={categories}
            title="Children"
            isLoading={isLoadingChildConstraints || isLoading}
            placeholder={placeholder}
            onTableDetails={onTableDetails}
            onTablePrefetch={onTablePrefetch} />
        </div>
      )}
    </Relationships>
  );
}

type RelationshipListTable = {
  id: string,
  name: string,
  schemaName: string,
  rowCount: number,
  projectCategory?: ProjectCategoryDto | null,
}

type AutoSizerState = {
  height: number;
  scaledHeight: number;
  scaledWidth: number;
  width: number;
};

export function RelationshipList({
  items, categories, isLoading, title, placeholder = <h5>None</h5>, onTableDetails, onTablePrefetch,
}: {
  items: RelationshipListTable[];
  categories: CategoryPreferenceMap;
  isLoading: boolean;
  title: string;
  placeholder?: ReactNode;
  onTableDetails: (tableName: string) => void;
  onTablePrefetch: OnTablePrefetch;
}) {
  return (
    <Card className="flex-grow-1">
      <CardHeader><h5 className="m-0">{title}</h5></CardHeader>
      <div className="ps-2 d-flex flex-grow-1">
        <LoadingSpinner isLoading={isLoading} centered>
          {items.length === 0
            ? placeholder
            : (
              <AutoSizer>
                {(autoSizerState: AutoSizerState) => (
                  <FixedSizeList
                    itemData={items}
                    itemCount={items.length}
                    itemSize={24}
                    height={autoSizerState.height}
                    width={autoSizerState.width}>
                    {({ index, data, style }) => (
                      <div
                        key={data[index].id}
                        style={{
                          ...style,
                        }}
                        className="pe-2 hover">
                        <RelationshipListItem
                          table={data[index]}
                          categories={categories}
                          onTableDetails={onTableDetails}
                          onTablePrefetch={onTablePrefetch} />
                      </div>
                    )}
                  </FixedSizeList>
                )}
              </AutoSizer>
            )}
        </LoadingSpinner>
      </div>
    </Card>
  );
}

function RelationshipListItem({
  table, categories, onTableDetails, onTablePrefetch,
}: {
  table: RelationshipListTable;
  categories: CategoryPreferenceMap
  onTableDetails: (tableName: string) => void;
  onTablePrefetch: OnTablePrefetch;
}) {
  const fullyQualifiedName = table
    ? `${table.schemaName}.${table.name}`
    : '';

  return (
    <TableItem
      table={table}
      categoryPrefMap={categories}
      onTableDetails={() => onTableDetails(fullyQualifiedName)}
      onTableFetch={onTablePrefetch} />
  );
}

export function LoadedRelationshipsSideBar({
  tableName, showDisabledReferences, showInferredReferences, showOverriddenReferences,
}: {
  tableName: string | undefined;
  showDisabledReferences: boolean;
  showInferredReferences: boolean;
  showOverriddenReferences: boolean;
}) {
  const { databaseId } = useCurrentDatabase();

  const { table, isLoading, isFetching } = useTableInfo(tableName);

  const { currentData: tables } = useUserApiListHarvestTablesQuery({
    harvestId: databaseId,
  });

  const { schemaName, tableName: encodedTableName } = useEncodedTableName(tableName);
  const {
    data: childConstraints,
    isLoading: isLoadingChildConstraints,
  } = useUserApiListTableChildConstraintsByTableNameQuery({
    harvestId: databaseId,
    schemaName,
    tableName: encodedTableName,
  }, {
    skip: isLoading || table?.isView || !tableName,
  });

  const openTableDetails = useTableDetails();
  const onTablePrefetch = useTablePrefetch();

  const { categories } = useLoadedCategoryColors();

  const [width, setWidth] = useUserPreferenceNumber('Diagram.RelationshipsWidth', 400);
  const [height, setHeight] = useUserPreferenceNumber('Diagram.RelationshipsParentHeight', 400);

  return (
    <Resizable
      width={width}
      height={0}
      minConstraints={[250, 0]}
      maxConstraints={[800, 0]}
      axis="x"
      resizeHandles={['w']}
      transformScale={2}
      handle={<span className="p-1" style={{ cursor: 'col-resize' }} />}
      onResize={(_, data) => setWidth(data.size.width)}>
      <div className="d-flex pt-2 flex-row-reverse">
        <div className="d-flex" style={{ width: `${width}px` }}>
          <RelationshipsSidebar
            table={table}
            tables={tables}
            childConstraints={childConstraints}
            isLoading={isLoading || (isFetching && !table)}
            isLoadingChildConstraints={isLoadingChildConstraints}
            showDisabledReferences={showDisabledReferences}
            showInferredReferences={showInferredReferences}
            showOverriddenReferences={showOverriddenReferences}
            categories={categories}
            splitHeight={height}
            onTableDetails={openTableDetails}
            onTablePrefetch={onTablePrefetch}
            onChangeSplit={setHeight} />
        </div>
      </div>
    </Resizable>
  );
}
