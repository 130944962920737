import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAppSelector } from '../app/hooks';
import { isAdmin, selectAuth } from '../features/authentication/authSlice';
import { useOrganizations } from '../features/organizations';
import { LoadingSpinner } from '../components/elements';
import { OrganizationRole } from './OrganizationRole';
import { hasOrgAuth } from './hasOrgAuth';

type OrgRouteProps = {
  children: JSX.Element;
  role?: OrganizationRole;
}

export function RequireOrganization({ children, role }: OrgRouteProps) {
  const {
    organizations, error, isLoading, organization,
  } = useOrganizations();
  const { user, isLoadingUser } = useAppSelector(selectAuth);

  if (isLoadingUser || !user) {
    return null;
  }

  if (error) {
    return (<p><em>Error checking organization permissions</em></p>);
  }

  return (
    <LoadingSpinner isLoading={isLoading || !organizations} centered>
      {
        isAdmin(user) || hasOrgAuth(organization, role, user)
          ? children
          : <Navigate replace to="/" />
      }
    </LoadingSpinner>
  );
}
