import { HTMLMdNodeConvertorMap } from '@toast-ui/editor';
import React, { useCallback, useEffect, useRef } from 'react';
import { Button } from 'reactstrap';
import { useBoundingRectangle } from '../../../features/database/diagram/useBoundingRectangle';
import { EditButton, IconButton } from '../buttons';
import { Icon } from '../icons';
import { ScrollArea } from '../scrollArea';
import { Editor, Viewer } from '../markdown';

export type ArticleVersion = {
  body: string;
  createdOn: string;
  authorFullName: string;
};

export function Article({
  onDiscard, onEdit, onHistory, onSave, onSaveDraft, article, title, isEditing,
}: {
  article: string | undefined;
  isEditing: boolean;
  title: string;
  onDiscard: () => void;
  onEdit: () => void;
  onHistory: () => void;
  onSave: (articleText: string) => void;
  onSaveDraft: (articleText: string) => void;
}) {
  const markdown = article ?? '';

  return (
    <div className="d-flex flex-grow-1 flex-column gap-2 mt-2">
      <ArticleHeader
        isEditing={isEditing}
        onEdit={onEdit}
        onHistory={onHistory}
        title={title} />
      {isEditing
        ? (
          <ArticleEditor
            markdown={markdown}
            onSave={onSave}
            onSaveDraft={onSaveDraft}
            onCancel={onDiscard} />
        )
        : (
          <ScrollArea className="d-flex">
            <ArticleViewer markdown={markdown} />
          </ScrollArea>
        )}
    </div>
  );
}

function ArticleHeader({
  isEditing, title, onEdit, onHistory,
}: {
  isEditing: boolean;
  title: string;
  onEdit: () => void;
  onHistory: () => void;
}) {
  const iconClass = isEditing
    ? 'd-flex gap-1 invisible'
    : 'd-flex gap-1';

  return (
    <h5 className="d-flex gap-1 justify-content-between">
      {title}
      <span className={iconClass}>
        <EditButton onClick={onEdit} />
        <IconButton onClick={onHistory} toolTip="History" iconName="history" />
      </span>
    </h5>
  );
}

export function ArticleViewer({ markdown, customHTMLRenderer }: {
  markdown: string;
  customHTMLRenderer?: HTMLMdNodeConvertorMap;
}) {
  const viewerRef = React.createRef<Viewer>();

  useEffect(() => {
    viewerRef.current?.getInstance().setMarkdown(markdown);
  }, [viewerRef, markdown]);

  return (
    <Viewer
      usageStatistics={false}
      ref={viewerRef}
      customHTMLRenderer={customHTMLRenderer}
      initialValue={markdown} />
  );
}

function ArticleEditor({
  markdown, onSave, onSaveDraft, onCancel,
  toolbarItems = [
    ['heading', 'bold', 'italic', 'strike'],
    ['hr', 'quote'],
    ['ul', 'ol', 'task', 'indent', 'outdent'],
    ['table', 'image', 'link'],
    ['code', 'codeblock'],
    ['scrollSync'],
  ],
}: {
  markdown: string;
  toolbarItems?: (string)[][],
  onSave: (article: string) => void;
  onSaveDraft: (article: string) => void;
  onCancel: () => void;
}) {
  const editorRef = React.createRef<Editor>();

  const onSaveClicked = useCallback(() => {
    const newMarkdown = editorRef.current?.getInstance().getMarkdown();
    if (newMarkdown && newMarkdown !== markdown) {
      onSave(newMarkdown);
      onCancel();
    } else {
      onCancel();
    }
  }, [editorRef, markdown, onCancel, onSave]);

  useEffect(() => {
    editorRef.current?.getInstance().setMarkdown(markdown);
  }, [editorRef, markdown]);

  useEffect(() => {
    const currentEditor = editorRef.current;
    return () => {
      const currentMarkdown = currentEditor?.getInstance().getMarkdown();
      if (currentMarkdown) onSaveDraft(currentMarkdown);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const boundRef = useRef<HTMLDivElement>(null);
  const bounds = useBoundingRectangle(boundRef.current);

  return (
    <div className="d-flex flex-column gap-2 flex-grow-1" ref={boundRef}>
      <Editor
        usageStatistics={false}
        previewStyle="vertical"
        initialEditType="wysiwyg"
        initialValue={markdown}
        height={`${(bounds?.height ?? 300) - 50}px`}
        toolbarItems={toolbarItems}
        ref={editorRef} />
      <div className="d-flex gap-2">
        <Button
          color="primary"
          className="ms-auto"
          onClick={onSaveClicked}>
          <Icon icon="save" /> Save
        </Button>
        <Button onClick={onCancel}>Cancel</Button>
      </div>
    </div>
  );
}
