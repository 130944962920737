import React, { useCallback, useMemo } from 'react';
import { useDrag } from 'react-dnd';
import { Column, Row } from 'react-table';
import {
  // eslint-disable-next-line max-len
  MmTableColumnDescriptorDto, MmCodeSetWithValueCountDto, useUserApiListMmTableColumnsByCodeSetQuery,
} from '../../../app/api';
import {
  LoadingSpinner, Table as SortTable, ErrorWrapper,
} from '../../../components/elements';
import { ErrorMessage } from '../../../components/elements/form';
import { useCurrentDatabase } from '../DatabaseContext';
import { useTableDetails } from '../tables';

export function CodeSetColumns({ codeSet }: {
  codeSet: MmCodeSetWithValueCountDto | undefined;
}) {
  const { databaseId } = useCurrentDatabase();

  const { data: codeValues, isLoading, error } = useUserApiListMmTableColumnsByCodeSetQuery({
    codeSetCode: codeSet?.code ?? -1,
    harvestId: databaseId,
  }, { skip: !codeSet });
  const memoizedData = useMemo(() => codeValues ?? [], [codeValues]);

  const tableName = useCallback((column: MmTableColumnDescriptorDto) => (
    <TableName column={column} />
  ), []);

  const columns: Column<MmTableColumnDescriptorDto>[] = useMemo(
    () => [
      {
        id: 'table',
        Header: 'Table',
        accessor: (d) => `${d.schemaName}.${d.tableName}`,
        Cell: (cellProps: { row: Row<MmTableColumnDescriptorDto>; }) => (
          tableName(cellProps.row.original)
        ),
      },
      {
        Header: 'Column',
        accessor: 'name',
      },
    ],
    [tableName],
  );

  const initialState = {
    sortBy: [
      { id: 'table', desc: false },
      { id: 'name', desc: false },
    ],
  };

  return (
    <div className="m-2 flex-grow-1 d-flex flex-column">
      <LoadingSpinner isLoading={isLoading} centered>
        <ErrorWrapper
          error={error}
          message={(
            <>
              <ErrorMessage statusCode={404}>
                <h4>Code Set {codeSet?.code} doesn&apos;t exist in the current harvest</h4>
              </ErrorMessage>
              <ErrorMessage>
                <h4>Unable to load Code Set values.</h4>
              </ErrorMessage>
            </>
          )}>
          <SortTable
            data={memoizedData}
            columns={columns}
            sort
            tableClassName="detail table-striped table-hover"
            filterPlaceholder="Find columns..."
            initialState={initialState} />
        </ErrorWrapper>
      </LoadingSpinner>
    </div>
  );
}

function TableName({ column }: {
  column: MmTableColumnDescriptorDto
}) {
  const fullyQualifiedTableName = `${column.schemaName}.${column.tableName}`;

  const onTableDetails = useTableDetails();

  const [, drag] = useDrag(() => ({
    type: 'Table',
    item: { schema: column.schemaName, name: column.tableName },
  }));

  return (
    <span
      ref={drag}
      onDoubleClick={() => onTableDetails(fullyQualifiedTableName)}
      className="cursor-grab user-select-none">
      {fullyQualifiedTableName}
    </span>
  );
}
