import React from 'react';
import Downshift from 'downshift';
import {
  Button, FormFeedback, FormGroup,
  Input, InputGroup, Label,
} from 'reactstrap';
import { ClearButton, Icon, LoadingSpinner } from '..';

export function DropdownSelect<T>({
  items, initialItem, disabled, placeholder, label, isLoading, autofocus, invalid,
  validationMessage, onChange, itemToString, filterItems, children, getItemKey,
}: {
  items: T[];
  initialItem: T | undefined;
  disabled: boolean;
  placeholder: string;
  label: string;
  isLoading?: boolean;
  autofocus?: boolean;
  invalid?: boolean;
  validationMessage?: string;
  onChange: (item: T | undefined) => void;
  itemToString: (item: T | null) => string;
  filterItems: (itemsList: T[], filterText: string | null) => T[];
  getItemKey: (item: T) => React.Key | null | undefined;
  children: (item: T) => React.ReactNode;
}) {
  return (
    <Downshift
      onChange={(item) => (item === null
        ? onChange(undefined)
        : onChange(item))}
      initialSelectedItem={initialItem}
      itemToString={itemToString}>
      {({
        getInputProps,
        getItemProps,
        getLabelProps,
        getMenuProps,
        isOpen,
        inputValue,
        highlightedIndex,
        selectedItem,
        openMenu,
        clearSelection,
      }) => (
        <div>
          <FormGroup>
            <Label {...getLabelProps()}>{label}</Label>
            <InputGroup className={validationMessage ? 'has-validation' : undefined}>
              <Input
                {...getInputProps()}
                invalid={invalid}
                autoFocus={autofocus}
                placeholder={placeholder}
                disabled={disabled}
                onFocus={() => openMenu()} />
              {!!selectedItem && (
                <ClearButton
                  disabled={disabled}
                  onClick={() => clearSelection()} />
              )}
              <LoadingSpinner
                isLoading={!!isLoading}
                spinner={(
                  <Button>
                    <Icon icon="spinner" spin />
                  </Button>
                )} />
              {!!validationMessage && <FormFeedback>{validationMessage}</FormFeedback>}
            </InputGroup>
            <ul
              {...getMenuProps()}
              className="dropdown-menu"
              style={{
                position: 'absolute',
                display: isOpen ? 'block' : 'none',
              }}>
              {isOpen
                ? filterItems(items, inputValue)
                  .slice(0, 9)
                  .map((item, index) => (
                    <li
                      className="dropdown-item"
                      {...getItemProps({
                        key: getItemKey(item),
                        index,
                        item,
                        style: {
                          backgroundColor: highlightedIndex === index ? 'var(--bs-primary)' : 'var(--bs-white)',
                          color: highlightedIndex === index ? 'var(--bs-white)' : 'var(--bs-body-color)',
                          fontWeight: selectedItem === item ? 'bold' : 'normal',
                        },
                      })}>
                      {children(item)}
                    </li>
                  ))
                : null}
            </ul>
          </FormGroup>
        </div>
      )}
    </Downshift>
  );
}
