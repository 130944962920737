import React from 'react';
import {
  MmCodeSetWithValueCountDto, useUserApiListMmCodeValuesByCodeSetQuery,
} from '../../../app/api';
import {
  LoadingSpinner, ErrorWrapper,
} from '../../../components/elements';
import { ErrorMessage } from '../../../components/elements/form';
import { useParentTabId } from '../../project';
import { CodeValueTable } from './CodeValueTable';

export function CodeSetValues({ codeSet }: {
  codeSet: MmCodeSetWithValueCountDto | undefined;
}) {
  const { data: codeValues, isLoading, error } = useUserApiListMmCodeValuesByCodeSetQuery({
    codeSetId: codeSet?.id ?? '',
  }, { skip: !codeSet });

  const tabId = useParentTabId();
  const stateScopeId = `${tabId}-code-values`;

  return (
    <div className="m-2 flex-grow-1 d-flex flex-column">
      <LoadingSpinner isLoading={isLoading} centered>
        <ErrorWrapper
          error={error}
          message={(
            <>
              <ErrorMessage statusCode={404}>
                <h4>Code Set {codeSet?.code} doesn&apos;t exist in the current harvest</h4>
              </ErrorMessage>
              <ErrorMessage>
                <h4>Unable to load Code Set values.</h4>
              </ErrorMessage>
            </>
          )}>
          <CodeValueTable
            codeValues={codeValues ?? []}
            stateScopeId={stateScopeId} />
        </ErrorWrapper>
      </LoadingSpinner>
    </div>
  );
}
