import React, { useEffect, useRef } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import {
  Button, Col, Form, FormGroup, Label, Modal, ModalBody, ModalFooter, ModalHeader,
} from 'reactstrap';
import { noop } from 'lodash';
import { DiagramRectangleDto } from '../../../../../app/api';
import {
  ColorInput,
  DEFAULT_COLOR,
  ErrorAlert, ErrorMessage, Icon, LoadingSpinner,
} from '../../../../../components/elements';
import { decimalToHexColor, hexColorToDecimal } from '../../../../../util';
import { useToggle } from '../../../../../hooks';
import { Editor } from '../../../../../components/elements/markdown';

type EditDiagramRectangleInputs = {
  backgroundColor: string;
  borderColor: string;
}

export function EditDiagramRectangleDialog({
  error, isOpen, isSaving, onClose, onUpdateRectangle, rectangle,
}: {
  error: unknown;
  isOpen: boolean;
  isSaving: boolean;
  onClose: () => void;
  onUpdateRectangle: (newRectangle: DiagramRectangleDto) => void;
  rectangle: DiagramRectangleDto | undefined;
}) {
  const editorRef = useRef<Editor>(null);

  const {
    control, handleSubmit, reset,
  } = useForm<EditDiagramRectangleInputs>({ disabled: isSaving });

  const submitForm: SubmitHandler<EditDiagramRectangleInputs> = async (formData) => {
    const newMarkdown = editorRef.current?.getInstance().getMarkdown();
    if (!rectangle) return;

    onUpdateRectangle({
      ...rectangle,
      content: newMarkdown ?? '',
      backgroundColor: hexColorToDecimal(formData.backgroundColor),
      borderColor: hexColorToDecimal(formData.borderColor),
    });
  };

  useEffect(() => {
    editorRef.current?.getInstance().setMarkdown(rectangle?.content ?? '');
    reset({
      backgroundColor: rectangle
        ? decimalToHexColor(rectangle.backgroundColor)
        : DEFAULT_COLOR,
      borderColor: rectangle
        ? decimalToHexColor(rectangle.borderColor)
        : DEFAULT_COLOR,
    });
  }, [rectangle, reset]);

  const { value: showEditor, setValue: setEditor } = useToggle(false);
  useEffect(() => {
    if (isOpen) {
      const selection = document.getSelection();
      selection?.empty();

      const x = setTimeout(() => {
        setEditor(true);
      }, 10);
      return () => clearTimeout(x);
    }
    setEditor(false);
    return noop;
  }, [isOpen, setEditor]);

  return (
    <Modal isOpen={isOpen} toggle={onClose} autoFocus={false} size="xl" onKeyDown={(e) => e.stopPropagation()}>
      <ModalHeader toggle={onClose}>
        Edit Rectangle
      </ModalHeader>
      <ModalBody>
        <ErrorAlert error={error}>
          <ErrorMessage statusCode={404}>
            Unable to save changes. Diagram rectangle could not be found.
          </ErrorMessage>
          <ErrorMessage>Unable to save changes.</ErrorMessage>
        </ErrorAlert>
        <Form onSubmit={handleSubmit(submitForm)}>
          <FormGroup row>
            <Label sm={2} for="background-color">
              Background
            </Label>
            <Col sm={10}>
              <Controller
                name="backgroundColor"
                control={control}
                defaultValue={DEFAULT_COLOR}
                render={({ field }) => (
                  <ColorInput
                    id="background-color"
                    {...field} />
                )} />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label sm={2} for="border-color">
              Border
            </Label>
            <Col sm={10}>
              <Controller
                name="borderColor"
                control={control}
                defaultValue={DEFAULT_COLOR}
                render={({ field }) => (
                  <ColorInput
                    id="border-color"
                    {...field} />
                )} />
            </Col>
          </FormGroup>
        </Form>
        <LoadingSpinner isLoading={!showEditor} centered>
          <Editor
            usageStatistics={false}
            previewStyle="vertical"
            initialEditType="wysiwyg"
            height="max(calc(90vh - 250px), 300px)"
            initialValue={rectangle?.content}
            toolbarItems={[
              ['heading', 'bold', 'italic', 'strike'],
              ['hr', 'quote'],
              ['ul', 'ol', 'task', 'indent', 'outdent'],
              ['table', 'link'],
              ['code', 'codeblock'],
              ['scrollSync'],
            ]}
            ref={editorRef} />
        </LoadingSpinner>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" disabled={isSaving} onClick={handleSubmit(submitForm)}>
          <LoadingSpinner isLoading={isSaving}><Icon icon="save" /></LoadingSpinner> Save
        </Button>
        <Button color="secondary" disabled={isSaving} onClick={onClose}>Cancel</Button>
      </ModalFooter>
    </Modal>
  );
}
