import { DateTime } from 'luxon';
import React, { useMemo } from 'react';
import { CellProps, Column } from 'react-table';
import { MmCodeValueDto } from '../../../app/api';
import { Table as SortTable } from '../../../components/elements';

export function CodeValueTable({ codeValues, showCodeSet, stateScopeId }: {
  codeValues: MmCodeValueDto[];
  showCodeSet?: boolean;
  stateScopeId: string;
}) {
  const memoizedData = useMemo(() => codeValues ?? [], [codeValues]);

  const dateCell = (
    cellProps: React.PropsWithChildren<CellProps<MmCodeValueDto, string | null | undefined>>,
  ) => {
    return (
      cellProps.value
        ? (
          <>
            {DateTime.fromISO(cellProps.value).toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS)}
          </>
        )
        : null
    );
  };

  const columns: Column<MmCodeValueDto>[] = useMemo(
    () => [
      {
        Header: 'Code',
        accessor: 'code',
      },
      {
        Header: 'Code Set',
        accessor: 'codeSetCode',
      },
      {
        Header: 'CDF Meaning',
        accessor: 'cdfMeaning',
      },
      {
        Header: 'Display',
        accessor: 'display',
      },
      {
        Header: 'Display Key',
        accessor: 'displayKey',
      },
      {
        Header: 'Description',
        accessor: 'description',
      },
      {
        Header: 'Definition',
        accessor: 'definition',
      },
      {
        Header: 'Sequence',
        accessor: 'sequence',
      },
      {
        Header: 'Active Type Cd',
        accessor: 'activeTypeCd',
      },
      {
        Header: 'Active Ind',
        accessor: 'activeInd',
      },
      {
        Header: 'Active Date Time',
        accessor: 'activeDtTm',
        Cell: dateCell,
      },
      {
        Header: 'Inactive Date Time',
        accessor: 'inactiveDtTm',
        Cell: dateCell,
      },
      {
        Header: 'Begin Effective Date Time',
        accessor: 'beginEffectiveDtTm',
        Cell: dateCell,
      },
      {
        Header: 'End Effective Date Time',
        accessor: 'endEffectiveDtTm',
        Cell: dateCell,
      },
      {
        Header: 'Data Status Cd',
        accessor: 'dataStatusCd',
      },
      {
        Header: 'Data Status Date Time',
        accessor: 'dataStatusDtTm',
        Cell: dateCell,
      },
      {
        Header: 'Data Status Prsnl Id',
        accessor: 'dataStatusPrsnlId',
      },
      {
        Header: 'Active Status Prsnl Id',
        accessor: 'activeStatusPrsnlId',
      },
      {
        Header: 'CKI',
        accessor: 'cki',
      },
      {
        Header: 'Display Key NLS',
        accessor: 'displayKeyNls',
      },
      {
        Header: 'Concept CKI',
        accessor: 'conceptCki',
      },
      {
        Header: 'Display KeyA NLS',
        accessor: 'displayKeyANls',
      },
      {
        Header: 'Last UTC Timestamp',
        accessor: 'lastUtcTs',
        Cell: dateCell,
      },
    ],
    [],
  );

  const initialState = {
    sortBy: [
      { id: 'code', desc: false },
    ],
    ...(!showCodeSet
      ? { hiddenColumns: ['codeSetCode'] }
      : {}),
  };

  return (
    <SortTable
      stateScopeId={stateScopeId}
      data={memoizedData}
      columns={columns}
      sort
      tableClassName="detail table-striped table-hover"
      filterPlaceholder="Find code values..."
      initialState={initialState} />
  );
}
