import React, { ReactNode } from 'react';
import { Badge } from 'reactstrap';
import { DiagramTagDto } from '../../../../../app/api';
import { Icon } from '../../../../../components/elements';
import styles from './DiagramTag.module.scss';

export function DiagramTag({ tag, children }: {
  tag: DiagramTagDto;
  children?: ReactNode;
}) {
  return (
    <Badge pill color="primary" className={`d-flex gap-1 ${styles['diagram-tag']}`}>
      <Icon icon="tag" />
      <span className={styles['tag-name']}>
        {tag.tagName}
      </span>
      {children}
    </Badge>
  );
}
