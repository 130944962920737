import { useCallback, useMemo } from 'react';
import { HarvestDto } from '../../app/api';
import { useProjectPreferences } from './hooks';

export function useFavoriteHarvests(harvests: HarvestDto[] | undefined) {
  const { preferences, setPreferences } = useProjectPreferences();

  const favoriteHarvests = useMemo(() => {
    const favoriteHarvestsMap = (preferences.favoriteHarvests ?? [])
      .reduce((acc: { [index: string]: string; }, hId) => {
        acc[hId] = hId;
        return acc;
      }, {});

    return harvests?.map((h) => ({ ...h, isFavorite: !!favoriteHarvestsMap[h.id] })) ?? [];
  }, [harvests, preferences.favoriteHarvests]);

  const addFavoriteHarvest = useCallback((harvestId: string) => {
    const currentFavorites = preferences?.favoriteHarvests ?? [];
    setPreferences({
      ...preferences,
      favoriteHarvests: [...currentFavorites, harvestId],
    });
  }, [preferences, setPreferences]);

  const removeFavoriteHarvest = useCallback((harvestId: string) => {
    const currentFavorites = preferences?.favoriteHarvests ?? [];
    setPreferences({
      ...preferences,
      favoriteHarvests: currentFavorites.filter((hId) => hId !== harvestId),
    });
  }, [preferences, setPreferences]);

  return {
    favoriteHarvests, addFavoriteHarvest, removeFavoriteHarvest,
  };
}
