import { ListGroup, ListGroupItem } from 'reactstrap';
import React, { ReactNode } from 'react';
import { Icon, LoadingSpinner } from '..';
import { ExpansionMap } from './treeSlice';

const DEFAULT_AUTO_EXPAND_THRESHOLD = 25;

export function TreeNode({
  children,
  label,
  childCount,
  filter,
  defaultExpanded,
  autoExpandThreshold = DEFAULT_AUTO_EXPAND_THRESHOLD,
  isLoading,
  className,
  id,
  expansionMap,
  showChildCount = true,
  onSetExpanded,
}: {
  children: ReactNode;
  label: string | ReactNode;
  childCount: number;
  filter: string;
  defaultExpanded: boolean;
  autoExpandThreshold?: number;
  isLoading?: boolean;
  className?: string;
  id: string;
  expansionMap: ExpansionMap;
  showChildCount?: boolean;
  onSetExpanded: (nodeId: string, expanded: boolean) => void;
}) {
  const explicitExpanded = expansionMap[id];

  const expanded = explicitExpanded === undefined
    ? defaultExpanded
    || (childCount <= (autoExpandThreshold ?? DEFAULT_AUTO_EXPAND_THRESHOLD) && !!filter)
    : !!explicitExpanded;
  const icon = expanded ? 'chevronDown' : 'chevronRight';

  return (
    <ListGroupItem className={`pe-0 ${className ?? ''}`}>
      <div className="d-flex cursor-pointer select-none hover" onClick={() => onSetExpanded(id, !expanded)}>
        <span className="me-auto d-flex gap-1 flex-grow-1">
          <div>
            <LoadingSpinner isLoading={isLoading ?? false}><Icon icon={icon} /></LoadingSpinner>
          </div>
          {label}
        </span>
        {!isLoading && showChildCount && (
          <span className="ms-3 text-muted">
            {childCount.toLocaleString()}
          </span>
        )}
      </div>
      {(expanded) && (
        <ListGroup>
          {children}
        </ListGroup>
      )}
    </ListGroupItem>
  );
}
