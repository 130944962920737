import React, { ReactNode } from 'react';
import Draggable, { DraggableData } from 'react-draggable';
import { Location } from './Location';
import { calculateOffset } from './util';
import styles from './DraggableItem.module.css';
import { DiagramBounds } from './hooks';
import { useSharedDragOffset } from './dragOffsetSlice';

export type DraggableItemProps = {
  location: Location;
  bounds: DiagramBounds,
  drawingOrigin: Location,
  scale: number,
  onDragEnd: (location: Location) => void;
  onDragStart?: () => void;
  onMove?: (data: DraggableData) => void;
  children: ReactNode;
  canMove: boolean;
  dragContextId: string,
  isSelected: boolean,
}

export const ZERO_LOCATION: Location = { x: 0, y: 0 };

function useDragOffset({
  viewBoxOrigin,
  drawingOrigin,
  scale,
  dragContextId,
  isSelected,
}: {
  drawingOrigin: Location,
  viewBoxOrigin: Location,
  scale: number,
  dragContextId: string,
  isSelected: boolean,
}) {
  const {
    delta, isDragging, initialViewBoxOrigin, initialDiagramOrigin,
    shiftDelta, startDrag, endDrag,
  } = useSharedDragOffset(dragContextId, isSelected);

  const offset = isDragging
    ? {
      x: delta.x
        - calculateOffset(drawingOrigin.x, initialDiagramOrigin.x, scale)
        + calculateOffset(viewBoxOrigin.x, initialViewBoxOrigin.x, scale),
      y: delta.y
        - calculateOffset(drawingOrigin.y, initialDiagramOrigin.y, scale)
        + calculateOffset(viewBoxOrigin.y, initialViewBoxOrigin.y, scale),
    }
    : ZERO_LOCATION;

  const onDragEnd = () => {
    endDrag();
  };

  const onDrag = (data: DraggableData) => {
    shiftDelta({
      deltaX: data.deltaX,
      deltaY: data.deltaY,
    });
  };

  const onDragStart = () => {
    startDrag(viewBoxOrigin, drawingOrigin);
  };

  return {
    offset,
    onDragEnd,
    onDrag,
    onDragStart,
  };
}

export function applyDragOffset(
  location: Location,
  offset: Location,
  bounds: DiagramBounds,
  scale: number,
) {
  return enforceBounds(
    {
      x: location.x + offset.x,
      y: location.y + offset.y,
    },
    bounds,
    scale,
  );
}

function enforceBounds(location: Location, bounds: DiagramBounds, scale: number) {
  const BUFFER = 50;
  const restrictSide = (start: number, size: number, point: number) => {
    return Math.min(
      Math.max(
        point,
        (start / scale) + BUFFER,
      ),
      ((start + size) / scale) - BUFFER,
    );
  };

  return {
    x: restrictSide(bounds.origin.x, bounds.width, location.x),
    y: restrictSide(bounds.origin.y, bounds.height, location.y),
  };
}

export function DraggableItem({
  bounds,
  drawingOrigin,
  scale,
  canMove,
  children,
  onDragEnd,
  onMove,
  onDragStart,
  location,
  dragContextId,
  isSelected,
}: DraggableItemProps) {
  const {
    offset,
    onDragEnd: endDrag,
    onDrag: drag,
    onDragStart: startDrag,
  } = useDragOffset({
    viewBoxOrigin: bounds.origin,
    drawingOrigin,
    scale,
    dragContextId,
    isSelected,
  });

  const position = offset === ZERO_LOCATION
    ? location
    : applyDragOffset(location, offset, bounds, scale);

  const onDrag = (_: unknown, data: DraggableData) => {
    if (onMove) {
      onMove(data);
    }
    drag(data);
  };

  const className = getClass(canMove, offset);

  return (
    <Draggable
      onDrag={onDrag}
      scale={scale}
      position={position}
      onMouseDown={(e) => e.stopPropagation()}
      onStop={(e) => {
        e.stopPropagation();
        endDrag();
        onDragEnd(offset);
      }}
      onStart={(e) => {
        e.stopPropagation();
        if (onDragStart) onDragStart();
        startDrag();
      }}
      defaultClassNameDragging={styles.dragging}
      bounds={{
        bottom: ((bounds.height + bounds.origin.y) / scale) - 50,
        top: (bounds.origin.y / scale) + 50,
        right: ((bounds.origin.x + bounds.width) / scale) - 50,
        left: (bounds.origin.x / scale) + 50,
      }}
      defaultClassName={className}
      disabled={!canMove}>
      <g>
        {children}
      </g>
    </Draggable>
  );
}

function getClass(canMove: boolean, offset: Location) {
  switch (true) {
    case offset !== ZERO_LOCATION:
      return styles.dragging;
    case canMove:
      return styles.draggable;
    default:
      return styles.undraggable;
  }
}
