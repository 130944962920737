import React, { ReactNode } from 'react';

import {
  Alert, Card, CardBody, CardSubtitle, CardText, Collapse,
} from 'reactstrap';
import { isFragment } from 'react-is';
import { Expander } from '..';
import { useToggle } from '../../../hooks';
import { isFetchBaseQueryError } from '../../../util';

type ErrorLevel = 'error' | 'warning' | 'info';

const errorLevelMap: { [K in ErrorLevel]: string } = {
  error: 'danger',
  warning: 'warning',
  info: 'info',
};

export function ErrorWrapper({
  level = 'error', message, error, children, className,
}: {
  level?: ErrorLevel;
  message: ReactNode | null;
  error: unknown;
  children: ReactNode;
  className?: string
}) {
  const color = errorLevelMap[level ?? 'error'];
  const { value: expanded, toggleValue: toggleExpanded } = useToggle(false);

  return !error
    // eslint-disable-next-line react/jsx-no-useless-fragment
    ? (<>{children}</>)
    : (
      <Alert color={color} className={className}>
        {message
          ? <ErrorMessageHandler error={error}>{message}</ErrorMessageHandler>
          : (<h4>Error</h4>)}

        <Card className={`alert-${color}`}>
          <CardBody>
            <CardSubtitle tag="h6">
              <Expander isCollapsed={!expanded} onToggle={toggleExpanded} /> Error Details...
            </CardSubtitle>
            <Collapse isOpen={expanded}>
              <CardText>
                {JSON.stringify(error)}
              </CardText>
            </Collapse>
          </CardBody>
        </Card>
      </Alert>
    );
}

function ErrorMessageHandler({ error, children }: {
  error: unknown;
  children: ReactNode | null;
}) {
  const renderedChildren = isFragment(children)
    ? children.props.children as ReactNode
    : children;

  const match = React.Children.toArray(renderedChildren)
    .filter((c) => React.isValidElement(c)
      && isFetchBaseQueryError(error)
      && c.props.statusCode === error.status)[0];

  return (
    <>
      {React.Children.map(renderedChildren, (child) => {
        if (!React.isValidElement(child) || (child.type as { name?: string }).name !== 'ErrorMessage') {
          return child;
        }

        return React.createElement(child.type, {
          ...{
            ...child.props,
            key: child.props.statusCode ?? 'default',
            error: child.props.statusCode || !match
              ? error
              : undefined,
          },
        });
      })}
    </>
  );
}
