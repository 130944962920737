import React from 'react';
import {
  Collapse, Navbar, NavbarBrand, NavbarToggler,
} from 'reactstrap';
import { Link } from 'react-router-dom';
import LoginMenu from '../auth/LoginMenu';
import './NavMenu.css';
import { BreadCrumb } from './BreadCrumb';
import { useToggle } from '../hooks';
import favicon from '../img/favicon.png';
import softekLogo from '../img/softek-logo-2019.svg';

export function NavMenu() {
  const { value: collapsed, toggleValue: toggleNavbar } = useToggle(true);
  return (
    <header>
      <Navbar
        className="navbar-expand-sm navbar-toggleable-sm ng-white border-bottom shadow sticky-top py-0"
        dark>
        <NavbarBrand tag={Link} to="/" className="text-nowrap">
          <img width="40" height="40" className="img-fluid me-2" src={favicon} alt="" />
          DB Explorer
        </NavbarBrand>
        <NavbarToggler onClick={toggleNavbar} className="me-2" />
        <Collapse className="d-sm-inline-flex flex-sm-row-reverse gap-sm-2" isOpen={!collapsed} navbar>
          <ul className="navbar-nav flex-grow">
            <LoginMenu />
          </ul>
          <a href="https://softekinc.com" className="nav-company-logo">
            <img height={25} src={softekLogo} alt="Softek" />
          </a>
          <ul className="me-auto navbar-nav">
            <BreadCrumb />
          </ul>
        </Collapse>
      </Navbar>
    </header>
  );
}
