import React, { useCallback, useRef, useState } from 'react';
import {
  Button, Form, FormGroup, Input, Label, Modal, ModalBody, ModalHeader, UncontrolledTooltip,
} from 'reactstrap';
import { DiagramWithChildrenDto, useUserApiUpdateDiagramMutation } from '../../../app/api';
import { Icon, LoadingSpinner } from '../../../components/elements';
import { useProject } from '../../project';
import { TableBodySort, translateTableBodySort } from './DiagramTable';

export type DiagramSettings = {
  isCategoryVisible: boolean,
  isCodeSetVisible: boolean,
  isDataTypeVisible: boolean
  isDistinctCountVisible: boolean,
  isSchemaNameVisible: boolean,
  isKeyIndicatorVisible: boolean,
  isRowCountVisible: boolean,
  isIndexIndicatorVisible: boolean,
  isFlagsIndicatorVisible: boolean,
  isDisabledReferencesVisible: boolean,
  isInferredReferencesVisible: boolean,
  isOverriddenReferencesVisible: boolean,
  hideNullableColumns: boolean,
  columnSortOrder: string,
}

export function DiagramSettingsControl({ diagram }: {
  diagram: DiagramWithChildrenDto;
}) {
  const { projectId } = useProject();

  const [dialogOpen, setDialogOpen] = useState(false);

  const [persistSettings, { isLoading: isSaving }] = useUserApiUpdateDiagramMutation();

  const onChange = useCallback((newSettings: DiagramSettings) => {
    persistSettings({
      diagramId: diagram.id,
      projectId,
      diagramCreateDto: {
        name: diagram.name,
        isLocked: diagram.isLocked,
        isPrivate: diagram.isPrivate,
        ...newSettings,
      },
    });
  }, [persistSettings, projectId, diagram]);

  return (
    <>
      <DiagramSettingsButton onClick={() => setDialogOpen(true)} />
      <DiagramSettingsDialog
        isOpen={dialogOpen}
        isSaving={isSaving}
        onClose={() => setDialogOpen(false)}
        onChange={onChange}
        settings={diagram}
        diagramName={diagram.name} />
    </>
  );
}

export function DiagramSettingsDialog({
  isOpen, isSaving, diagramName, settings, onChange, onClose,
}: {
  isOpen: boolean,
  isSaving: boolean,
  diagramName: string;
  settings: DiagramSettings;
  onChange: (s: DiagramSettings) => void;
  onClose: () => void;
}) {
  const headerHelp = useRef(null);

  const close = onClose;
  const isEnabled = !isSaving;

  return (
    <Modal isOpen={isOpen} toggle={close}>
      <ModalHeader
        toggle={close}>
        <LoadingSpinner isLoading={isSaving}><Icon icon="settings" /></LoadingSpinner>
        {' '}{diagramName} Diagram Settings <span ref={headerHelp}><Icon icon="helpTip" /></span>
      </ModalHeader>
      <ModalBody className="d-flex flex-column gap-3">
        <div>
          <h6>Table Headers</h6>
          <Form>
            <Toggle
              title="Show Category"
              settingName="isCategoryVisible"
              settings={settings}
              onChange={onChange}
              isEnabled={isEnabled} />
            <Toggle
              title="Show Fully Qualified Name"
              settingName="isSchemaNameVisible"
              settings={settings}
              onChange={onChange}
              isEnabled={isEnabled} />
            <Toggle
              title="Show Row Count"
              settingName="isRowCountVisible"
              settings={settings}
              onChange={onChange}
              isEnabled={isEnabled} />
          </Form>
        </div>
        <div>
          <h6>Table Body</h6>
          <Form>
            <Toggle
              title="Show Code Set"
              settingName="isCodeSetVisible"
              settings={settings}
              onChange={onChange}
              isEnabled={isEnabled} />
            <Toggle
              title="Show Data Type"
              settingName="isDataTypeVisible"
              settings={settings}
              onChange={onChange}
              isEnabled={isEnabled} />
            <Toggle
              title="Show Distinct Values"
              settingName="isDistinctCountVisible"
              settings={settings}
              onChange={onChange}
              isEnabled={isEnabled} />
            <Toggle
              title="Hide Nullable Columns"
              settingName="hideNullableColumns"
              settings={settings}
              onChange={onChange}
              isEnabled={isEnabled} />
            <Toggle
              title="Show PK/FK Indicator"
              settingName="isKeyIndicatorVisible"
              settings={settings}
              onChange={onChange}
              isEnabled={isEnabled} />
            <Toggle
              title="Show Index Indicator"
              settingName="isIndexIndicatorVisible"
              settings={settings}
              onChange={onChange}
              isEnabled={isEnabled} />
            <Toggle
              title="Show Flags Indicator"
              settingName="isFlagsIndicatorVisible"
              settings={settings}
              onChange={onChange}
              isEnabled={isEnabled} />
            <DiagramSortSelector
              settings={settings}
              onChange={onChange}
              isEnabled={isEnabled} />
          </Form>
          <div>
            <h6>References</h6>
            <Form>
              <Toggle
                title="Show Disabled References"
                settingName="isDisabledReferencesVisible"
                settings={settings}
                onChange={onChange}
                isEnabled={isEnabled} />
            </Form>
            <Form>
              <Toggle
                title="Show Inferred References"
                settingName="isInferredReferencesVisible"
                settings={settings}
                onChange={onChange}
                isEnabled={isEnabled} />
            </Form>
            <Form>
              <Toggle
                title="Show Reference Overrides"
                settingName="isOverriddenReferencesVisible"
                settings={settings}
                onChange={onChange}
                isEnabled={isEnabled} />
            </Form>
          </div>
        </div>
      </ModalBody>
      <UncontrolledTooltip target={headerHelp} placement="bottom">
        Settings are applied to a single diagram.
        All users viewing the diagram will be affected by these settings.
      </UncontrolledTooltip>
    </Modal>
  );
}

type FilteredKeys<T, U> = {
  [P in keyof T]: T[P] extends U ? P : never
}[keyof T];

function Toggle({
  isEnabled, title, settings, settingName, onChange,
}: {
  isEnabled: boolean,
  title: string,
  settings: DiagramSettings
  settingName: FilteredKeys<DiagramSettings, boolean>,
  onChange: (newSettings: DiagramSettings) => void,
}) {
  const onCheckChange = (value: boolean) => onChange({
    ...settings,
    [settingName]: value,
  });

  const id = `diagram-setting-${settingName}`;

  return (
    <FormGroup check>
      <Input
        type="checkbox"
        id={id}
        disabled={!isEnabled}
        checked={settings[settingName]}
        onChange={(e) => onCheckChange(e.target.checked)} />
      <Label check for={id}>
        {' '}
        {title}
      </Label>
    </FormGroup>
  );
}

function DiagramSettingsButton({ onClick }: {
  onClick: () => void,
}) {
  return (
    <>
      <Button
        className="btn-sm"
        onClick={() => onClick()}
        id="diagram-settings-button">
        <Icon icon="settings" fixedWidth />
      </Button>
      <UncontrolledTooltip placement="top" target="diagram-settings-button">
        Diagram settings
      </UncontrolledTooltip>
    </>
  );
}

const sortTypeMap: { [K in TableBodySort]: string } = {
  ByColumnId: 'Column Id',
  ByColumnName: 'Column Name',
  ByDistinctCount: 'Distinct Values',
  ForeignKeysFirst: 'Foreign Key',
};

function DiagramSortSelector({ isEnabled, settings, onChange }: {
  isEnabled: boolean
  settings: DiagramSettings
  onChange: (newSettings: DiagramSettings) => void,
}) {
  const onSelectionChanged = (value: string) => onChange({ ...settings, columnSortOrder: value });

  return (
    <FormGroup className="d-flex gap-2">
      <Label className="col-form-label" for="sort">
        Sort
      </Label>
      <Input
        id="sort"
        name="select"
        type="select"
        disabled={!isEnabled}
        value={translateTableBodySort(settings.columnSortOrder)}
        onChange={(e) => onSelectionChanged(e.target.value)}>
        {
          Object.keys(sortTypeMap).map((k) => (
            <option value={k} key={k}>
              {sortTypeMap[k as TableBodySort]}
            </option>
          ))
        }
      </Input>
    </FormGroup>
  );
}
