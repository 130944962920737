import React, { forwardRef, Ref, useState } from 'react';
import TusUploady, {
  // eslint-disable-next-line max-len
  BatchItem, FILE_STATES, useClearResumableStore, useItemAbortListener, useItemCancelListener, useItemErrorListener,
  useItemFinalizeListener, useItemFinishListener, useItemProgressListener, useItemStartListener,
  useRequestPreSend,
} from '@rpldy/tus-uploady';
import { asUploadButton } from '@rpldy/upload-button';
import {
  Button,
  Modal, ModalBody, ModalFooter, ModalHeader, Progress,
} from 'reactstrap';
import retryEnhancer, { useRetry } from '@rpldy/retry-hooks';
import { useProject } from '../ProjectContext';
import { useCurrentUser } from '../../authentication/useCurrentUser';
import { Icon } from '../../../components/elements';
import { useAppDispatch } from '../../../app/hooks';
import { api } from '../../../app/api';

export function UploadHarvestDialog({ modal, toggleModal }: {
  modal: boolean;
  toggleModal: () => void;
}) {
  const { projectId } = useProject();
  const user = useCurrentUser();

  return (
    <TusUploady
      multiple={false}
      featureDetection
      enhancer={retryEnhancer}
      destination={{
        url: '/uploads',
        params: {
          projectId,
        },
        headers: {
          authorization: `Bearer ${user.access_token}`,
        },
      }}
      chunkSize={2142880}>
      <Modal isOpen={modal} toggle={toggleModal} backdrop="static">
        <ModalHeader toggle={toggleModal}>Upload a Harvest</ModalHeader>
        <HarvestUploader onClose={toggleModal} />
      </Modal>
      <DynamicUploadMeta />
    </TusUploady>
  );
}

const UploadButton = asUploadButton(forwardRef(
  (props, ref: Ref<Button>) => <Button {...props} ref={ref}><Icon icon="upload" /> Upload File</Button>,
));

function DynamicUploadMeta() {
  useRequestPreSend(({ items, options }) => {
    return {
      items,
      options: {
        ...options,
        params: {
          ...options.params,
          filename: items[0].file.name,
          filetype: items[0].file.type,
        },
      },
    };
  });

  return null;
}

type FileStateMap<T> = {
  [K in FILE_STATES]: T;
}

const fileStateColorMap: FileStateMap<'primary' | 'warning' | 'danger' | 'success'> = {
  pending: 'primary',
  uploading: 'primary',
  aborted: 'warning',
  cancelled: 'warning',
  error: 'danger',
  added: 'primary',
  finished: 'success',
};

const fileStateTextMap: FileStateMap<string> = {
  pending: 'Pending...',
  added: 'Pending...',
  uploading: 'Uploading...',
  aborted: 'Failed',
  cancelled: 'Canceled',
  error: 'Failed',
  finished: 'Import Queued...',
};

const fileStateRetryMap: FileStateMap<boolean> = {
  pending: false,
  added: false,
  uploading: false,
  finished: false,
  aborted: true,
  cancelled: true,
  error: true,
};

function useRefreshCurrentProjectHarvestJob() {
  const { projectId } = useProject();
  const dispatch = useAppDispatch();
  useItemFinishListener(() => {
    dispatch(api.util.invalidateTags([{ type: 'HarvestJob', id: `project-${projectId}` }]));
  });
}

function HarvestUploader({ onClose }: {
  onClose: () => void;
}) {
  const progressEntries = useUploadProgress();
  const retry = useRetry();
  const clearResumables = useClearResumableStore();
  useRefreshCurrentProjectHarvestJob();

  return (
    <>
      <ModalBody>
        {progressEntries.length <= 0
          ? <UploadButton onClick={clearResumables} />
          : progressEntries
            .map(([id, item]) => {
              return (
                <div key={id}>
                  <p>{item.url || item.file.name}: {fileStateTextMap[item.state]}</p>
                  <Progress
                    color={fileStateColorMap[item.state]}
                    value={item.completed} />
                  {(item.state === 'finished') && (<p>Harvest has been uploaded. The harvest will be imported into the database and will be available in Database Explorer as soon as possible.</p>)}
                </div>
              );
            })}
      </ModalBody>
      {progressEntries.length > 0
        && progressEntries.every(([, item]) => item.completed === 100
          && item.state !== FILE_STATES.PENDING
          && item.state !== FILE_STATES.UPLOADING)
        && (
          <ModalFooter>
            {progressEntries.some(([, item]) => fileStateRetryMap[item.state])
              && (
                <Button onClick={() => retry()}><Icon icon="retry" /> Retry</Button>
              )}
            <Button onClick={() => onClose()}>Close</Button>
          </ModalFooter>
        )}
    </>
  );
}

function useUploadProgress() {
  const [uploads, setUploads] = useState<{ [k: string]: BatchItem; }>({});
  const updateStatus = (progressData: BatchItem) => {
    setUploads({
      ...uploads,
      [progressData.id]: progressData,
    });
  };

  useItemStartListener((item) => updateStatus(item));
  useItemFinishListener((item) => updateStatus(item));
  useItemProgressListener((item) => updateStatus(item));
  useItemCancelListener((item) => updateStatus(item));
  useItemErrorListener((item) => updateStatus(item));
  useItemAbortListener((item) => updateStatus(item));
  useItemFinalizeListener((item) => updateStatus(item));

  const entries = Object.entries(uploads);
  return entries;
}
