import { Alert } from 'reactstrap';
import React, { ReactNode } from 'react';
import { isFetchBaseQueryError } from '../../../../util';

export function ErrorAlert({ error, color = 'danger', children }: {
  error: unknown;
  color?: string;
  children: ReactNode;
}) {
  const match = React.Children.toArray(children)
    .filter((c) => React.isValidElement(c)
      && isFetchBaseQueryError(error)
      && c.props.statusCode === error.status)[0];

  return (
    <Alert color={color} isOpen={!!error}>
      {React.Children.map(children, (child) => {
        if (!React.isValidElement(child)) {
          return child;
        }

        return React.createElement(child.type, {
          ...{
            ...child.props,
            key: child.props.statusCode ?? 'default',
            error: child.props.statusCode || !match
              ? error
              : undefined,
          },
        });
      })}
    </Alert>
  );
}
