import React, {
  useEffect, useMemo, useRef, useState, useCallback, forwardRef, Ref,
} from 'react';
import {
  Button, ButtonGroup, Input, Label, UncontrolledTooltip,
} from 'reactstrap';
import { isEqual, uniqWith } from 'lodash';
import { mergeRefs } from 'react-merge-refs';
import {
  DiagramWithChildrenDto,
  DiagramRectangleDto,
  DiagramTableDto,
  getRootEntity,
  isMmColumn,
  MmTableColumnDto,
  // eslint-disable-next-line max-len
  MmTableDto, TableDto, TableSummaryDto, useUserApiCreateDiagramRectangleMutation, useUserApiCreateDiagramTableMutation, useUserApiDeleteDiagramRectangleMutation, useUserApiDeleteDiagramTableMutation, useUserApiGetDiagramByIdQuery, useUserApiUpdateDiagramRectangleMutation, useUserApiUpdateDiagramTableMutation,
} from '../../../app/api';
import { DrawingTable } from './DiagramTable';
import { withTableData } from './withTableData';
import { withBodyCategoryColorization } from './withBodyCategoryColorization';
import {
  DiagramTableHeaderColor, withHeaderRowCountColorization,
} from './withHeaderRowCountColorization';
import { DEFAULT_COLOR, Icon, LoadingSpinner } from '../../../components/elements';
import {
  // eslint-disable-next-line max-len
  DEFAULT_DOCUMENT, DiagramBounds, PAGE_SIZE, useEditLock, useSaveAsPng, useLocalDrop, Box, useDiagramAutoSize, useSelectArea, unscaleBounds, useMouseMode,
} from './hooks';
import { DiagramSettingsControl } from './DiagramSettings';
import { CategoryPreferenceMap, useLoadedCategoryColors, useLoadedThresholdColors } from '../settings';
import { UpdateWrapper, useSubscribeToUpdates } from './forceUpdates';
import { useResizeObservable } from './useResizeObservable';
import { RefConnector } from './RefConnector';
import { LoadedRelationshipsSideBar, RelationshipButton } from './Relationships';
import { SelectionMap, useDiagramState } from './diagramSlice';
import { useProject } from '../../project';
import styles from './DiagramViewer.module.css';
import { useBoundingRectangle } from './useBoundingRectangle';
import { useTableDetails } from '../tables';
import { useDebouncedEventHandler } from '../../../hooks';
import { hexColorToDecimal, insensitiveCompare } from '../../../util';
import { translateTableBodySort } from './DiagramTable/util';
import { UpdatableDraggableItem } from './UpdatableDraggableItem';
import { Location } from './Location';
import { useCodeSetDetails } from '../codeSets';
import { useUserPreferenceToggle } from '../../userPreferences';
import { ScrollPosition } from '../../../components/elements/scrollArea/scrollSlice';
import { ColorSettingsControl } from '../settings/ColorSettings';
import { applyDragOffset, DraggableItem } from './DraggableItem';
import { OnTablePrefetch, useTablePrefetch } from '../tables/useTablePrefetch';
import { DiagramRectangle } from './DiagramRectangle';
import { EditDiagramRectangle } from './EditDiagramRectangle';
import { ResizableItem, SizeDefinition } from './ResizableItem';
import { DiagramRectangleOutline } from './DiagramRectangleOutline';

const LoadedTable = withTableData(
  withHeaderRowCountColorization(
    withBodyCategoryColorization(
      DrawingTable,
    ),
  ),
);

export type DiagramObjectBounds = {
  x: number;
  y: number;
  width: number;
  height: number;
  isScaled: boolean;
}

export type DiagramElementRef = {
  id: string;
  name: string;
  element: SVGGElement | null
  bounds: DiagramObjectBounds | undefined;
};

export type RefMap = { [K: string]: DiagramElementRef };

export function DiagramViewer({ diagramId }: {
  diagramId: string;
}) {
  const { projectId } = useProject();

  const {
    diagramState, setZoom, selectObject, selectObjects, setScroll, setDiagramBounds,
  } = useDiagramState(diagramId);

  const [showRelationshipList, toggleRelationshipList] = useUserPreferenceToggle('Diagram.ShowRelationshipList', true);
  const [mouseMode, setMouseMode] = useMouseMode();

  const openTableDetails = useTableDetails();
  const openCodeSetDetails = useCodeSetDetails();
  const { data, isLoading } = useUserApiGetDiagramByIdQuery(
    {
      projectId,
      diagramId,
    },
  );

  const canEdit = !useEditLock(data);

  const [persistAddTable] = useUserApiCreateDiagramTableMutation();
  const [persistTableMove] = useUserApiUpdateDiagramTableMutation();
  const [persistRemovedTable] = useUserApiDeleteDiagramTableMutation();

  const [persistRectangleUpdate] = useUserApiUpdateDiagramRectangleMutation();
  const [persistRemovedRectangle] = useUserApiDeleteDiagramRectangleMutation();
  const [persistAddRectangle] = useUserApiCreateDiagramRectangleMutation();

  const moveTable = async (table: DiagramTableDto, location: Location) => {
    if (canEdit) {
      await persistTableMove({
        diagramId,
        diagramTableId: table.id,
        diagramTableUpdateDto: {
          ...table,
          x: Math.round(location.x),
          y: Math.round(location.y),
          order: 0,
        },
      });
    }
  };

  const updateRectangle = async (
    rectangle: DiagramRectangleDto,
  ) => {
    if (canEdit) {
      await persistRectangleUpdate({
        diagramId,
        diagramRectangleId: rectangle.id,
        diagramRectangleUpdateDto: rectangle,
      });
    }
  };

  const moveRectangle = async (
    rectangle: DiagramRectangleDto,
    location: Location,
  ) => {
    updateRectangle(
      {
        ...rectangle,
        x: Math.round(location.x),
        y: Math.round(location.y),
      },
    );
  };

  const resizeRectangle = async (
    rectangle: DiagramRectangleDto,
    newSize: SizeDefinition,
  ) => {
    updateRectangle(
      {
        ...rectangle,
        x: Math.round(newSize.x),
        y: Math.round(newSize.y),
        width: Math.round(newSize.width),
        height: Math.round(newSize.height),
      },
    );
  };

  const reorderRectangle = async (
    rectangle: DiagramRectangleDto,
    order: number,
  ) => {
    updateRectangle(
      {
        ...rectangle,
        order,
      },
    );
  };

  const addRectangle = async (box: SizeDefinition) => {
    if (canEdit) {
      const result = await persistAddRectangle({
        diagramId,
        diagramRectangleCreateDto: {
          backgroundColor: hexColorToDecimal(DEFAULT_COLOR),
          borderColor: 0,
          content: '',
          x: Math.round(box.x),
          y: Math.round(box.y),
          width: Math.round(box.width),
          height: Math.round(box.height),
        },
      });

      if ('data' in result) {
        selectObject(result.data.id, false);
      }
    }
  };

  const removeRectangle = (rectangle: DiagramRectangleDto) => {
    if (canEdit) {
      persistRemovedRectangle({
        diagramId,
        diagramRectangleId: rectangle.id,
      });
    }
  };

  const removeSelectedObjects = () => {
    if (diagramState.selectedObjects && canEdit) {
      data?.tables.filter((t) => diagramState.selectedObjects[t.id])
        .forEach((t) => {
          persistRemovedTable({
            diagramId,
            diagramTableId: t.id,
          }).then(() => selectObjects([]));
        });

      data?.rectangles.filter((t) => diagramState.selectedObjects[t.id])
        .forEach((r) => {
          persistRemovedRectangle({
            diagramId,
            diagramRectangleId: r.id,
          }).then(() => selectObjects([]));
        });
    }
  };

  const keyMap: { [k: string]: () => void } = {
    Delete: removeSelectedObjects,
  };

  const onKeyDown = (e: React.KeyboardEvent<Element>) => keyMap[e.key] && keyMap[e.key]();

  const onScroll = useDebouncedEventHandler((x: HTMLDivElement) => {
    setScroll({ left: x.scrollLeft, top: x.scrollTop });
  }, 50);
  const scrollToPosition = (scrollPosition: ScrollPosition) => {
    if (scrollRef.current?.scrollTop !== scrollPosition.top
      || scrollRef.current?.scrollLeft !== scrollPosition.left) {
      scrollRef.current?.scroll(scrollPosition);
    }
  };

  const rootRef = useRef<HTMLDivElement>(null);

  const [, forceUpdate] = useState({});
  const onScrollAreaMutate = useCallback(() => {
    const bounds = rootRef.current?.getBoundingClientRect();
    if (bounds) {
      forceUpdate({});
    }
  }, []);
  useResizeObservable(rootRef.current, onScrollAreaMutate);

  const scrollRef = useRef<HTMLDivElement>(null);

  const { categories } = useLoadedCategoryColors();
  const { thresholds } = useLoadedThresholdColors();

  const drawingRef = useRef<SVGSVGElement>(null);
  const { saveAsPng } = useSaveAsPng(drawingRef.current, data?.name, diagramState.scale);
  const drawingOrigin = useBoundingRectangle(drawingRef.current);

  useEffect(() => {
    if (drawingOrigin) {
      scrollToPosition(diagramState.scrollPosition);
    }
  }, [diagramState.scrollPosition, drawingOrigin]);

  const oldBounds = useRef<DiagramBounds>();
  const onDiagramBoundsChange = useCallback((bounds: DiagramBounds) => {
    if (bounds !== DEFAULT_DOCUMENT) {
      if (oldBounds.current && scrollRef.current) {
        const shiftLeft = (oldBounds.current.origin.x - bounds.origin.x);
        const shiftTop = (oldBounds.current.origin.y - bounds.origin.y);
        if (Math.round(Math.abs(shiftLeft / diagramState.scale)) === PAGE_SIZE
          || Math.round(Math.abs(shiftTop / diagramState.scale)) === PAGE_SIZE) {
          setScroll({
            left: scrollRef.current.scrollLeft + (shiftLeft),
            top: scrollRef.current.scrollTop + (shiftTop),
          });
        }
      }
      oldBounds.current = bounds;
      setDiagramBounds(unscaleBounds(bounds, diagramState.scale));
    }
  }, [diagramState.scale, setDiagramBounds, setScroll]);

  const addTable = useCallback(async (
    item: Pick<TableSummaryDto, 'name'> & { schema: string },
    location: Location,
  ) => {
    if (canEdit) {
      const diagramOrigin = oldBounds.current?.origin ?? { x: 0, y: 0 };
      const scrollPosition = {
        scrollLeft: scrollRef.current?.scrollLeft ?? 0,
        scrollTop: scrollRef.current?.scrollTop ?? 0,
      };
      const diagramOffset = {
        x: (diagramOrigin.x + scrollPosition.scrollLeft) / diagramState.scale,
        y: (diagramOrigin.y + scrollPosition.scrollTop) / diagramState.scale,
      };

      const result = await persistAddTable({
        diagramId,
        diagramTableCreateDto: {
          tableName: `${item.schema}.${item.name}`,
          x: Math.round(location.x + diagramOffset.x),
          y: Math.round(location.y + diagramOffset.y),
        },
      });

      if ('data' in result) {
        selectObject(result.data.id, false);
      }
    }
    return ({ name: 'Diagram' });
  }, [canEdit, diagramState.scale, persistAddTable, diagramId, selectObject]);

  const drop = useLocalDrop<HTMLDivElement, Pick<TableSummaryDto, 'name'> & { schema: string }, void>(
    addTable,
    diagramState.scale,
    canEdit,
  );

  const selectedTables = data?.tables
    .filter((t) => diagramState.selectedObjects[t.id]);
  const selectedTableName = selectedTables?.length === 1
    ? selectedTables[0].tableName
    : undefined;

  const onTablePrefetch = useTablePrefetch();

  return (
    <div className="d-flex flex-column flex-grow-1 pe-1 pb-1" ref={rootRef}>
      <div className="d-flex flex-grow-1">
        <div
          className="d-flex flex-grow-1 overflow-auto"
          onScroll={(e) => onScroll(e.currentTarget)}
          onKeyDown={onKeyDown}
          ref={mergeRefs([scrollRef, drop])}>
          <div
            className="flex-grow-1"
            style={{ height: '1px', width: '1px' }}>
            <LoadingSpinner isLoading={isLoading} centered>
              <EditDiagramRectangle diagram={data}>
                {(onEditRectangle) => (
                  <DiagramDrawing
                    diagram={data}
                    zoom={diagramState.scale}
                    ref={drawingRef}
                    onAddRectangle={addRectangle}
                    onDeleteRectangle={removeRectangle}
                    onEditRectangle={onEditRectangle}
                    onMoveTable={moveTable}
                    onMoveRectangle={moveRectangle}
                    onSetRectangleOrder={reorderRectangle}
                    origin={drawingOrigin ?? { x: 0, y: 0 }}
                    selected={diagramState.selectedObjects}
                    onSetSelected={selectObject}
                    onSelectMany={selectObjects}
                    onOpenCodeset={openCodeSetDetails}
                    onOpenTable={openTableDetails}
                    onResizeRectangle={resizeRectangle}
                    onTablePrefetch={onTablePrefetch}
                    canEdit={canEdit}
                    categoryColors={categories}
                    thresholdColors={thresholds}
                    initialBounds={diagramState.bounds}
                    onDiagramBoundsChange={onDiagramBoundsChange} />
                )}
              </EditDiagramRectangle>
            </LoadingSpinner>
          </div>
        </div>
        {showRelationshipList && (
          <LoadedRelationshipsSideBar
            key={selectedTableName}
            tableName={selectedTableName}
            showDisabledReferences={!!data?.isDisabledReferencesVisible}
            showInferredReferences={!!data?.isInferredReferencesVisible}
            showOverriddenReferences={!!data?.isOverriddenReferencesVisible} />
        )}
      </div>
      <div className="d-flex align-items-center gap-2 mt-2">
        <ButtonGroup>
          <Button
            id="toggle-select"
            className="btn-sm"
            active={mouseMode === 'Select'}
            onClick={() => setMouseMode('Select')}>
            <Icon icon="select" />
          </Button>
          <UncontrolledTooltip placement="top" target="toggle-select">
            Select/Drag
          </UncontrolledTooltip>
          <Button
            id="toggle-draw-rectangle"
            className="btn-sm"
            active={mouseMode === 'DrawRectangle'}
            onClick={() => setMouseMode('DrawRectangle')}>
            <Icon icon="drawRectangle" />
          </Button>
          <UncontrolledTooltip placement="top" target="toggle-draw-rectangle">
            Draw Group/Note
          </UncontrolledTooltip>
        </ButtonGroup>
        <Label className="text-nowrap mb-0">
          Zoom: {Math.round(diagramState.scale * 100)}%
        </Label>
        <Input
          type="range"
          min=".1"
          max="3"
          step=".1"
          value={diagramState.scale}
          onChange={(e) => setZoom(Number.parseFloat(e.target.value))}
          className="flex-shrink-1" />
        <ColorSettingsControl />
        {canEdit && !!data && (
          <DiagramSettingsControl
            diagram={data} />
        )}

        <RelationshipButton
          onClick={toggleRelationshipList}
          isActive={showRelationshipList} />
        <Button className="btn-sm" id="export-diagram" onClick={saveAsPng}>
          <Icon icon="export" fixedWidth />
        </Button>
        <UncontrolledTooltip placement="top" target="export-diagram">
          Export diagram as PNG
        </UncontrolledTooltip>
      </div>
    </div>
  );
}

export const COLUMN_STYLES = {
  notNullableStyle: 'fw-bold',
  emptyStyle: {
    class: 'text-muted',
    threshold: 1,
  },
};

type DiagramDrawingProps = {
  canEdit: boolean;
  categoryColors: CategoryPreferenceMap;
  diagram: DiagramWithChildrenDto | undefined;
  origin: Location;
  selected: SelectionMap;
  thresholdColors: DiagramTableHeaderColor[];
  zoom: number;
  initialBounds?: DiagramBounds;
  onAddRectangle?: (size: SizeDefinition) => void;
  onDeleteRectangle?: (rectangle: DiagramRectangleDto) => void;
  onDiagramBoundsChange?: (bounds: DiagramBounds) => void;
  onEditRectangle?: (rectangle: DiagramRectangleDto) => void;
  onMoveTable?: (table: DiagramTableDto, location: Location) => void;
  onMoveRectangle?: (rectangle: DiagramRectangleDto, location: Location) => void;
  onOpenCodeset?: (id: number) => void;
  onOpenTable?: (id: string) => void;
  onResizeRectangle?: (rectangle: DiagramRectangleDto, newSize: SizeDefinition) => void;
  onSetSelected?: (id: string, toggle: boolean) => void;
  onSelectMany?: (id: string[]) => void;
  onSetRectangleOrder?: (rectangle: DiagramRectangleDto, order: number) => void;
  onTablePrefetch?: OnTablePrefetch;
}

export const DiagramDrawing = forwardRef((
  {
    canEdit,
    categoryColors,
    diagram,
    origin,
    selected,
    thresholdColors,
    zoom,
    initialBounds,
    onAddRectangle,
    onDeleteRectangle,
    onDiagramBoundsChange,
    onEditRectangle,
    onMoveTable,
    onMoveRectangle,
    onOpenCodeset,
    onOpenTable,
    onResizeRectangle,
    onTablePrefetch,
    onSetSelected,
    onSelectMany,
    onSetRectangleOrder,
  }: DiagramDrawingProps,
  ref: Ref<SVGSVGElement>,
) => {
  const sortedTables = useMemo(
    () => [...(diagram?.tables ?? [])].sort((a, b) => {
      if (selected[a.id] !== selected[b.id]) {
        if (selected[a.id]) return 1;
        if (selected[b.id]) return -1;
      }
      return b.order - a.order;
    }),
    [diagram?.tables, selected],
  );

  const objectRefs = useRef<RefMap>({});

  useEffect(() => {
    const tableSet = new Set([
      ...(diagram?.tables.map((t) => t.id) ?? []),
      ...(diagram?.rectangles.map((r) => r.id) ?? []),
    ]);

    Object.keys(objectRefs.current).forEach((id) => {
      if (!tableSet.has(id)) {
        delete objectRefs.current[id];
      }
    });
  }, [diagram]);

  const {
    diagramBounds,
    triggerResize,
  } = useDiagramAutoSize(
    zoom,
    objectRefs,
    diagram,
    origin,
    initialBounds ?? DEFAULT_DOCUMENT,
    selected,
  );

  const scaleFill = (size: number) => (zoom < 1 ? size / zoom : size * zoom);

  const settings = useMemo(() => ({
    isCategoryVisible: true,
    isCodeSetVisible: true,
    isDataTypeVisible: true,
    isDistinctCountVisible: true,
    isSchemaNameVisible: true,
    isKeyIndicatorVisible: true,
    isRowCountVisible: true,
    isIndexIndicatorVisible: true,
    isFlagsIndicatorVisible: true,
    isDisabledReferencesVisible: true,
    isInferredReferencesVisible: true,
    isOverriddenReferencesVisible: true,
    hideNullableColumns: false,
    columnSortOrder: 'columnId',
    ...diagram,
  }), [diagram]);

  const onMove = () => {
    triggerResize();
  };

  const saveMovedObjects = (offset: Location) => {
    if (onMoveTable) {
      sortedTables
        .filter((table) => selected[table.id])
        .forEach((table) => {
          onMoveTable(table, applyDragOffset(table, offset, diagramBounds, zoom));
        });
    }

    if (onMoveRectangle) {
      diagram?.rectangles
        .filter((rectangle) => selected[rectangle.id])
        .forEach((rectangle) => {
          onMoveRectangle(rectangle, applyDragOffset(rectangle, offset, diagramBounds, zoom));
        });
    }
  };

  const renderedTables = useMemo(() => {
    return (
      <>
        {sortedTables
          .map((i) => (
            <UpdatableDraggableItem
              key={i.id}
              location={i}
              drawingOrigin={origin}
              scale={zoom}
              onDragEnd={(offset: Location) => {
                saveMovedObjects(offset);
              }}
              bounds={diagramBounds}
              canMove={canEdit}
              dragContextId="diagram"
              isSelected={!!selected[i.id]}
              onMove={onMove}>
              <LoadedTable
                diagramTable={i}
                isSelected={!!selected[i.id]}
                tableName={i.tableName}
                showCategory={settings.isCategoryVisible}
                showCodeSet={settings.isCodeSetVisible}
                showDataType={settings.isDataTypeVisible}
                showDistinctValues={settings.isDistinctCountVisible}
                showFullyQualifiedName={settings.isSchemaNameVisible}
                showPkFkIndicator={settings.isKeyIndicatorVisible}
                showRowCount={settings.isRowCountVisible}
                showIndexIndicator={settings.isIndexIndicatorVisible}
                showFlagsIndicator={settings.isFlagsIndicatorVisible}
                showNullableColumns={!settings.hideNullableColumns}
                showDisabledForeignKeys={settings.isDisabledReferencesVisible}
                showInferredForeignKeys={settings.isInferredReferencesVisible}
                showOverriddenReferences={settings.isOverriddenReferencesVisible}
                sort={translateTableBodySort(settings.columnSortOrder)}
                onSelect={(toggle: boolean) => onSetSelected && onSetSelected(i.id, toggle)}
                onOpenCodeSet={onOpenCodeset}
                onTableDetails={(tableName) => onOpenTable && onOpenTable(tableName)}
                onTablePrefetch={onTablePrefetch}
                scale={zoom}
                columnStyles={COLUMN_STYLES}
                headerColors={thresholdColors}
                categoryColors={categoryColors}
                ref={(r: DiagramElementRef) => {
                  objectRefs.current[i.id] = r;
                }} />
            </UpdatableDraggableItem>
          ))}
      </>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    onOpenCodeset,
    onSetSelected,
    onOpenTable,
    onMoveTable,
    selected,
    canEdit,
    thresholdColors,
    categoryColors,
    settings,
    sortedTables,
    triggerResize,
    zoom,
  ]);

  useEffect(() => {
    if (onDiagramBoundsChange) {
      onDiagramBoundsChange(diagramBounds);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [diagramBounds]);

  const setMultiselect = (area: Box) => {
    if (onSelectMany) {
      const selectedTables = Object.values(objectRefs.current)
        .filter((tr) => {
          const tableRect = tr.element?.getBoundingClientRect();
          return tableRect
            && tableRect.left >= area.left
            && tableRect.right <= area.right
            && tableRect.top >= area.top
            && tableRect.bottom <= area.bottom;
        })
        .map((tr) => tr.id);
      onSelectMany(selectedTables);
    }
  };

  const addRectangle = (area: Box) => {
    if (onAddRectangle) {
      onAddRectangle({
        x: (area.x - origin.x + diagramBounds.origin.x) / zoom,
        y: (area.y - origin.y + diagramBounds.origin.y) / zoom,
        height: area.height / zoom,
        width: area.width / zoom,
      });
    }
  };

  const [mouseMode] = useMouseMode();

  const { containerProps, selectionProps } = useSelectArea(
    mouseMode === 'Select'
      ? setMultiselect
      : addRectangle,
    origin,
  );

  return (
    <UpdateWrapper>
      <div>
        <svg
          ref={ref}
          tabIndex={0}
          {...containerProps}
          height={diagramBounds.height}
          width={diagramBounds.width}
          style={{
            overflow: 'auto',
            backgroundColor: '#ffffff',
            cursor: mouseMode === 'Select' ? 'inherit' : 'crosshair',
          }}
          className={styles.diagram}
          viewBox={`${diagramBounds.origin.x} ${diagramBounds.origin.y} `
            + `${diagramBounds.width} ${diagramBounds.height}`}>
          <defs>
            <filter id="dropshadow" x="-100%" y="-100%" width="300%" height="300%">
              <feOffset result="offOut" in="SourceAlpha" dx="3" dy="3" />
              <feGaussianBlur result="blurOut" in="offOut" stdDeviation="3" />
              <feBlend in="SourceGraphic" in2="blurOut" mode="normal" />
            </filter>
            <pattern id="page" width="1000" height="1000" patternUnits="userSpaceOnUse">
              <rect width="1000" height="1000" fill="url(#grid)" />
              <path d="M 1000 0 L 0 0 0 1000" fill="none" stroke="black" strokeWidth="1.5" />
            </pattern>
            <pattern id="tenthGrid" width="10" height="10" patternUnits="userSpaceOnUse">
              <path d="M 10 0 L 0 0 0 10" fill="none" stroke="silver" strokeWidth="0.5" />
            </pattern>
            <pattern id="grid" width="100" height="100" patternUnits="userSpaceOnUse">
              <rect width="100" height="100" fill="url(#tenthGrid)" />
              <path d="M 100 0 L 0 0 0 100" fill="none" stroke="gray" strokeWidth="1" />
            </pattern>
            <marker
              id="arrow"
              viewBox="0 0 10 10"
              refX="0"
              refY="5"
              markerWidth="12"
              markerHeight="12"
              orient="auto-start-reverse">
              <path d="M 0 0 L 10 5 L 0 10 z" />
            </marker>
            <marker
              id="arrowHighlight"
              viewBox="0 0 10 10"
              refX="1.5"
              refY="5"
              markerWidth="4"
              markerHeight="4"
              stroke="#0dcaf0"
              orient="auto-start-reverse">
              <path d="M 0 0 L 10 5 L 0 10 z" fill="#0dcaf0" />
            </marker>
          </defs>
          <g transform={`scale(${zoom})`}>
            <rect
              id="paper"
              width={scaleFill(diagramBounds.width)}
              height={scaleFill(diagramBounds.height)}
              x={diagramBounds.origin.x / zoom}
              y={diagramBounds.origin.y / zoom}
              fill="url(#page)" />

            {!!diagram && [...diagram.rectangles].sort((a, b) => b.order - a.order).map((r) => (
              <DraggableItem
                key={r.id}
                location={r}
                drawingOrigin={origin}
                scale={zoom}
                onDragEnd={(offset: Location) => {
                  saveMovedObjects(offset);
                }}
                bounds={diagramBounds}
                canMove={canEdit}
                dragContextId="diagram"
                isSelected={!!selected[r.id]}
                onMove={onMove}>
                <ResizableItem
                  size={{
                    ...r,
                    x: 0,
                    y: 0,
                  }}
                  scale={zoom}
                  resizeContextId={r.id}
                  onResize={(newSize) => onResizeRectangle && onResizeRectangle(r, {
                    ...newSize,
                    x: r.x + newSize.x,
                    y: r.y + newSize.y,
                  })}>
                  {(size) => (
                    <g transform={`translate(${size.x}, ${size.y})`}>
                      <DiagramRectangle
                        rectangle={{
                          ...r,
                          x: r.x + size.x,
                          y: r.y + size.y,
                          width: size.width,
                          height: size.height,
                        }}
                        canEdit={canEdit}
                        diagram={diagram}
                        isSelected={!!selected[r.id]}
                        onDelete={() => (
                          canEdit
                          && onDeleteRectangle
                          && onDeleteRectangle(r)
                        )}
                        onEdit={() => (
                          canEdit
                          && onEditRectangle
                          && onEditRectangle(r))}
                        onSetOrder={(o) => (
                          canEdit
                          && onSetRectangleOrder
                          && onSetRectangleOrder(r, o)
                        )}
                        onSelect={(toggle: boolean) => onSetSelected && onSetSelected(r.id, toggle)}
                        ref={(rf) => {
                          if (rf) objectRefs.current[r.id] = rf;
                        }} />
                    </g>
                  )}
                </ResizableItem>
              </DraggableItem>
            ))}

            {diagram?.tables?.map((t) => (
              <TableRelationships
                key={t.id}
                diagramTable={t}
                tableName={t.tableName}
                refs={objectRefs.current ?? []}
                offset={{
                  x: origin.x - (diagramBounds.origin.x),
                  y: origin.y - (diagramBounds.origin.y),
                }}
                scale={zoom}
                showDisabledReferences={settings.isDisabledReferencesVisible}
                showInferredReferences={settings.isInferredReferencesVisible}
                showOverriddenReferences={settings.isOverriddenReferencesVisible} />
            ))}

            {renderedTables}

            {diagram?.rectangles
              .filter((r) => !!selected[r.id])
              .map((r) => (
                <DiagramRectangleOutline
                  key={`${r.id}-outline`}
                  onResizeRectangle={onResizeRectangle}
                  scale={zoom}
                  rectangle={r}
                  dragContextId="diagram"
                  isSelected />
              ))}
          </g>

          {!!selectionProps && (
            <rect
              {...translatePoint(selectionProps, diagramBounds, origin)}
              style={{
                fill: '#0dcaf0',
                fillOpacity: 0.2,
                strokeWidth: '2',
                stroke: '#0dcaf0',
                stopOpacity: 0.8,
              }} />
          )}
        </svg>
      </div>
    </UpdateWrapper>
  );
});

function translatePoint<T extends Location>(
  point: T,
  diagramBounds: DiagramBounds,
  origin: Location,
) {
  return {
    ...point,
    x: point.x - origin.x + diagramBounds.origin.x,
    y: point.y - origin.y + diagramBounds.origin.y,
  };
}

type TableRelationshipsProps = {
  table: TableDto | (TableDto & MmTableDto) | undefined;
  diagramTable: DiagramTableDto;
  isLoading: boolean;
  tableName: string;
  refs: RefMap;
  offset: { x: number, y: number };
  scale: number;
  showDisabledReferences: boolean;
  showInferredReferences: boolean;
  showOverriddenReferences: boolean;
}

const TableRelationships = withTableData((props: TableRelationshipsProps) => {
  useSubscribeToUpdates();
  const refSignature = Object.keys(props.refs).join();
  const relationShips = useMemo(() => {
    return uniqWith(
      (
        props.table?.referentialConstraints
          .filter((c) => c.isEnabled || props.showDisabledReferences)
          .flatMap(
            (c) => Object.values(props.refs)
              .filter((x) => insensitiveCompare(x.name, `${c.parentConstraint?.tableIndex?.schemaName}.${c.parentConstraint?.tableIndex?.tableName}`) === 0),
          ) ?? []
      ).concat(
        props.table?.tableColumns
          .filter((c): c is MmTableColumnDto => isMmColumn(c))
          .filter((c): c is MmTableColumnDto => props.showInferredReferences)
          .map((c) => getRootEntity(c, props.showOverriddenReferences))
          .flatMap(
            (c) => Object.values(props.refs)
              .filter((x) => insensitiveCompare(x.name, `V500.${c.rootEntityName}`) === 0),
          ) ?? [],
      ),
      isEqual,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    refSignature,
    props.showDisabledReferences,
    props.showInferredReferences,
    props.showOverriddenReferences,
    props.table?.referentialConstraints,
    props.table?.tableColumns]);

  return (
    <>
      {relationShips.filter((p) => p.id !== props.diagramTable.id)
        .map((r) => (
          <RefConnector
            key={`${r.id}-to-${props.diagramTable.id}`}
            parent={r.element}
            child={props.refs[props.diagramTable.id]?.element}
            drawingOrigin={props.offset}
            scale={props.scale}
            markerSize={12} />
        ))}
    </>
  );
});
